import { ReactNode } from 'react';
import { Stack, SxProps, Theme, Typography } from '@mui/material';
import useMobileView from 'src/hooks/useMobileView';

export const SettingsTabContainer = ({ children }: { children: ReactNode }) => {
    return <Stack gap='8px'>{children}</Stack>;
};

const generateMobileStyles = (isMobile: boolean) => {
    return isMobile
        ? {
              flexDirection: 'column',
              rowGap: '16px',
          }
        : {
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              columnGap: '16px',
              width: '100%',
          };
};

export const SettingField = ({
    children,
    name,
    description,
}: {
    children: ReactNode;
    name: string;
    description: string;
}) => {
    const isMobile = useMobileView();
    const mobileStyles: SxProps<Theme> = generateMobileStyles(isMobile);

    return (
        <Stack sx={mobileStyles}>
            <Stack rowGap='3px' fontSize='16px'>
                <Typography variant='subtitle2' fontWeight={500} fontSize={'inherit'}>
                    {name}
                </Typography>
                <Typography color='text.secondary' variant='body1' fontSize={'inherit'}>
                    {description}
                </Typography>
            </Stack>
            {children}
        </Stack>
    );
};
