import { useEffect, useRef, useState } from 'react';
import { Alert, Button, Stack, Typography } from '@mui/material';
import OneTimePasscode from 'src/components/user-settings/profile/info/Email/UpdateEmailModal/UpdateEmail/Steps/OneTimePasscode/OneTimePasscode';
import { useTranslationNs } from 'src/hooks/useTranslationNs';

const EnterVerificationCode = (props: {
    verifyCode: (c: string) => void;
    handleSendVerificationEmail: () => void;
    codeExpired: boolean;
    invalidCode: boolean;
    setInvalidCode: (c: boolean) => void;
}) => {
    let { verifyCode, handleSendVerificationEmail, codeExpired, invalidCode, setInvalidCode } =
        props;
    const { t } = useTranslationNs();

    const inputRef = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState<string>('');

    const verifyEmail = () => {
        verifyCode(value);
    };

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    useEffect(() => {
        if (codeExpired) {
            setValue('');
        }
    }, [codeExpired]);

    useEffect(() => {
        if (value.length !== 6) {
            setInvalidCode(false);
        }
    }, [value]);

    return (
        <Stack>
            {invalidCode && (
                <Alert
                    severity='error'
                    sx={{
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {t('USER_SETTINGS.PROFILE.EMAIL_UPDATE.VERIFY_CODE.ERROR.WRONG_CODE')}
                </Alert>
            )}
            {codeExpired && (
                <Alert
                    severity='error'
                    sx={{
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {t('USER_SETTINGS.PROFILE.EMAIL_UPDATE.VERIFY_CODE.ERROR.EXPIRED')}
                </Alert>
            )}

            <Typography>{t('USER_SETTINGS.PROFILE.EMAIL_UPDATE.VERIFY_CODE.PROMPT')}</Typography>
            <OneTimePasscode
                length={6}
                error={invalidCode}
                disabled={codeExpired}
                value={value}
                setValue={setValue}
            />
            <Stack
                direction='column'
                justifyContent='center'
                alignItems='center'
                gap={1}
                marginTop={4}
            >
                {!codeExpired && (
                    <Button
                        variant='contained'
                        onClick={verifyEmail}
                        disabled={value.length !== 6}
                        sx={{ width: '100%', textTransform: 'unset' }}
                    >
                        {t('COMMON.BUTTONS.SUBMIT')}
                    </Button>
                )}
                <Button
                    variant='text'
                    onClick={() => handleSendVerificationEmail()}
                    sx={{ width: '100%', textTransform: 'unset' }}
                >
                    {t('USER_SETTINGS.PROFILE.EMAIL_UPDATE.RESEND')}
                </Button>
            </Stack>
        </Stack>
    );
};

export default EnterVerificationCode;
