import Close from '@mui/icons-material/Close';
import { DialogContent, DialogTitle, IconButton, Stack, Theme, SxProps } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { ReactNode, forwardRef } from 'react';
import useMobileView from 'src/hooks/useMobileView';

const MobileTransition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction='up' ref={ref} {...props} />;
});

const WebTransition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Fade ref={ref} {...props} />;
});

export const SwitcherDialog = ({
    isOpen,
    dialogTitle,
    dialogCloseTitle,
    dialogTitleSlot,
    dialogContentSx = {},
    onClose,
    children,
}: {
    isOpen: boolean;
    dialogTitle: string;
    dialogCloseTitle: string;
    dialogTitleSlot?: ReactNode;
    dialogContentSx?: SxProps<Theme>;
    onClose: () => void;
    children: ReactNode;
}) => {
    const isMobileView = useMobileView();

    return (
        <Dialog
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: '24px',
                    width: '450px',
                    maxWidth: 'calc(100% - 32px)',
                    py: '12px',
                },
            }}
            open={isOpen}
            onClose={onClose}
            TransitionComponent={isMobileView ? MobileTransition : WebTransition}
        >
            <Stack padding='0px 24px'>
                <Stack
                    position='relative'
                    justifyContent='space-between'
                    alignItems='center'
                    direction='row'
                    padding='16px 0px 16px 0px'
                >
                    <DialogTitle sx={{ padding: 0, fontWeight: 700 }} variant='h6'>
                        {dialogTitle}
                    </DialogTitle>
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            right: '-10px',
                            transform: 'translateY(-50%)',
                        }}
                        title={dialogCloseTitle}
                        onClick={onClose}
                    >
                        <Close />
                    </IconButton>
                </Stack>
                {!!dialogTitleSlot && dialogTitleSlot}
            </Stack>
            <DialogContent sx={dialogContentSx}>{children}</DialogContent>
        </Dialog>
    );
};
