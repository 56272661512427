import { forwardRef, ReactElement, Ref } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';
import useMobileView from 'src/hooks/useMobileView';
import { DialogProps, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { ThemeValues } from 'src/constants/theme-values';
import Close from '@mui/icons-material/Close';

const MobileTransition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement;
    },
    ref: Ref<unknown>,
) {
    return <Slide direction='up' ref={ref} {...props} />;
});

const WebTransition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement;
    },
    ref: Ref<unknown>,
) {
    return <Fade ref={ref} {...props} />;
});

export const CommonDialog = ({
    children,
    onClose,
    ...dialogProps
}: { onClose: () => void } & Omit<DialogProps, 'onClose'>) => {
    const isMobileView = useMobileView();

    const handleClose: DialogProps['onClose'] = (event, reason) => {
        if (reason && reason === 'backdropClick') return;
        onClose();
    };

    return (
        <Dialog
            maxWidth={isMobileView ? undefined : false}
            {...dialogProps}
            TransitionComponent={isMobileView ? MobileTransition : WebTransition}
            PaperProps={{ sx: { borderRadius: ThemeValues.DialogBorderRadius } }}
            onClose={handleClose}
        >
            <IconButton
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    top: '12px',
                    right: '16px',
                }}
            >
                <Close />
            </IconButton>
            {children}
        </Dialog>
    );
};
