import { Box, Link, List, Typography } from '@mui/material';
import { Fragment } from 'react';
import { DialogListItemActionButton } from 'src/components/common/Dialog/DialogListItemActionButton';
import { useApplicationsContext, useOrganizationContext } from 'src/contexts';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { getContextRedirectUrl } from 'src/utils/navigation.utils';

export const ApplicationAllAppsList = ({ onAppClick }: { onAppClick: () => void }) => {
    const { t } = useTranslationNs();
    const { userApplications, application } = useApplicationsContext();
    const { organization } = useOrganizationContext();

    if (userApplications.length === 0) {
        return (
            <Box sx={{ px: '24px' }}>
                <Typography variant='body1'>
                    {t('APPLICATION_CONTEXT.DIALOG.NO_RESULTS')}
                </Typography>
            </Box>
        );
    }

    return (
        <List>
            {userApplications.map((app) => {
                const appName = app.name;
                return (
                    <Fragment key={appName}>
                        <DialogListItemActionButton
                            buttonProps={{
                                href: getContextRedirectUrl(
                                    organization.organizationName,
                                    app.redirectUrl,
                                ),
                                target: '_blank',
                                component: Link,
                            }}
                            selected={appName === application?.name}
                            onListItemButtonClick={onAppClick}
                        >
                            <Typography variant='body1'>{app?.displayName}</Typography>
                        </DialogListItemActionButton>
                    </Fragment>
                );
            })}
        </List>
    );
};
