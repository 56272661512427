import { useTranslationNs } from 'src/hooks/useTranslationNs';
import LogoutButton from 'src/components/common/LogoutButton/LogoutButton';
import { SettingGroup } from 'src/components/user-settings/common/SettingGroup';

const LogoutPrompt = () => {
    const { t } = useTranslationNs({
        keyPrefix: 'USER_SETTINGS.PROFILE',
    });

    return (
        <SettingGroup title={t('LOGOUT')}>
            <LogoutButton variant='outlined' sx={{ textTransform: 'unset' }} />
        </SettingGroup>
    );
};

export default LogoutPrompt;
