import { UserClaims } from '@okta/okta-auth-js';
import { createContext, useContext } from 'react';
import { RccEvents } from 'src/classes/event-emitter';

export type IRccContext = {
    isAuthenticated: boolean;
    isInternalUser: boolean;
    events: RccEvents;
    userInfo: UserClaims;
    _TEMPORARY_userSettingsMutationEndpoint?: string;
    _TEMPORARY_globalProfileOktaSdkEnabled?: boolean;
    _TEMPORARY_globalProfilePasswordResetEnabled?: boolean;
    refreshUserInfo: () => Promise<void>;
};

export const RccContext = createContext<IRccContext>({} as IRccContext);

export const useRccContext = () => useContext(RccContext);
