import { Close } from '@mui/icons-material';
import { Box, IconButton, Modal, Stack, Tab, Tabs, Theme, Typography } from '@mui/material';
import { ReactNode, useState } from 'react';
import { ThemeValues } from 'src/constants/theme-values';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { UserPreferences } from './preferences/UserPreferences';
import { UserProfile } from 'src/components/user-settings/profile/UserProfile';
import { UserSecurity } from 'src/components/user-settings/security/UserSecurity';
import { useRccContext } from 'src/contexts';
import useMobileView from 'src/hooks/useMobileView';

const a11yTabPrefix = 'account-tab';
const a11yTabPanelPrefix = 'account-tabpanel';
const a11yProps = (index: number) => ({
    id: `${a11yTabPrefix}-${index}`,
    'aria-controls': `${a11yTabPanelPrefix}-${index}`,
});

const wrapperStyles: { width: string; maxWidth: string } = {
    width: '100%',
    maxWidth: '820px',
};

type TabPanelProps = {
    children?: ReactNode;
    index: number;
    value: number;
};

type TabData = {
    title: string;
    component: ReactNode;
};

const UserSettingsTabPanel = ({ children, value, index }: TabPanelProps) => {
    const modalHeaderHeight = 168;
    return (
        <Box
            role='tabpanel'
            hidden={value !== index}
            id={`${a11yTabPanelPrefix}-${index}`}
            aria-labelledby={`${a11yTabPrefix}-${index}`}
            sx={{
                overflowY: 'auto',
                maxHeight: `calc(90vh - ${modalHeaderHeight}px)`,
            }}
        >
            {value === index && (
                <Stack alignItems='center' padding={ThemeValues.UserSettingsPadding}>
                    <Box {...wrapperStyles}>{children}</Box>
                </Stack>
            )}
        </Box>
    );
};

export const UserSettingsModal = ({
    isOpen,
    onClose,
}: {
    isOpen: boolean;
    onClose: () => void;
}) => {
    const { t } = useTranslationNs({
        keyPrefix: 'USER_SETTINGS',
    });
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const { isInternalUser, _TEMPORARY_globalProfilePasswordResetEnabled } = useRccContext();

    let tabs: TabData[] = [
        {
            title: t('PROFILE.TITLE'),
            component: <UserProfile />,
        },
        {
            title: t('PREFERENCES.TITLE'),
            component: <UserPreferences />,
        },
    ];

    // TODO: re-enable when CORS support is enabled
    if (!isInternalUser && _TEMPORARY_globalProfilePasswordResetEnabled) {
        tabs = tabs.concat({
            title: t('SECURITY.TITLE'),
            component: <UserSecurity />,
        });
    }

    const handleTabChange = (_, newValue: number) => {
        setActiveTabIndex(newValue);
    };
    
    const isMobileView = useMobileView();
    
    return (
        <Modal onClose={onClose} open={isOpen}>
            <Box
                sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: isMobileView ? '100%' : '65vw',
                    height: isMobileView ? '100%' : '90vh',
                    borderRadius: '8px',
                    backgroundColor: (theme: Theme) => theme.palette.background.default,
                    overflowY: 'hidden',
                }}
            >
                <Stack
                    sx={{ padding: '12px', width: '100%' }}
                    justifyContent='flex-end'
                    direction='row'
                >
                    <IconButton title={t('CLOSE_BUTTON_TITLE')} onClick={onClose}>
                        <Close />
                    </IconButton>
                </Stack>
                <Stack
                    alignItems='center'
                    sx={{
                        padding: `8px ${ThemeValues.UserSettingsPadding} 0px`,
                        borderBottom: '1px solid',
                        borderColor: 'divider',
                    }}
                >
                    <Stack alignItems='flex-start' rowGap='24px' {...wrapperStyles}>
                        <Typography fontWeight={400} variant='h4'>
                            {t('TITLE')}
                        </Typography>
                        <Tabs value={activeTabIndex} onChange={handleTabChange}>
                            {tabs.map((td: TabData, idx: number) => (
                                <Tab
                                    sx={{ textTransform: 'none' }}
                                    key={td.title}
                                    label={td.title}
                                    {...a11yProps(idx)}
                                />
                            ))}
                        </Tabs>
                    </Stack>
                </Stack>
                {tabs.map((td: TabData, idx: number) => (
                    <UserSettingsTabPanel key={td.title} value={activeTabIndex} index={idx}>
                        {td.component}
                    </UserSettingsTabPanel>
                ))}
            </Box>
        </Modal>
    );
};
