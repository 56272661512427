import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { ApiError, Organization } from '../types';

export type IOrganizationContext = {
    organization: Organization | null;
    isOrgDialogOpen: boolean;
    setIsOrgDialogOpen: Dispatch<SetStateAction<boolean>>;
    userOrganizations: Array<Organization>;
    updateOrganization: (org: string) => void;
    totalOrganizations: number;
    error: ApiError | null;
    hasPreloaded: boolean;
    isLoading: boolean;
    isLoadingOrg: boolean;
};

export const OrganizationContext = createContext<IOrganizationContext>({} as IOrganizationContext);

export const useOrganizationContext = () => useContext(OrganizationContext);
