import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { AppConstants } from 'src/constants/app';
import enjson from './locales/en/rcc.json';

const i18nCommon = i18n.use(initReactI18next)
    .createInstance(
        {
            resources: {
                en: {
                    [AppConstants.i18NS]: enjson,
                },
            },
            ns: AppConstants.i18NS,
            defaultNS: AppConstants.i18NS,
            fallbackLng: 'en',
            lng: 'en',
            interpolation: {
                escapeValue: false,
            },
        },
        (err) => {
            if (err) console.log(err);
        },
    );
i18nCommon.init();

export default i18nCommon;
