import { createContext, useContext } from 'react';
import { RecentOrganization } from 'src/types/user-preferences.type';
import { ApiError, Organization } from '..';

export type IOrganizationsCacheContext = {
    recentOrganizations: RecentOrganization[];
    recentOrganizationsMutationError: ApiError;
    isRecentOrganizationsLoading: boolean;
    getRecentOrganizationsError: ApiError;
    isRecentOrganizationsMutating: boolean;
    updateUserRecentOrganizations: (organization: Organization) => Promise<void>;
    deleteCache: () => Promise<void>;
};

export const OrganizationsCacheContext = createContext<IOrganizationsCacheContext>(
    {} as IOrganizationsCacheContext,
);

export const useOrganizationsCacheContextInternal = () => useContext(OrganizationsCacheContext);

export const useOrganizationsCacheContext = () => {
    const {
        recentOrganizations,
        recentOrganizationsMutationError,
        isRecentOrganizationsLoading,
        getRecentOrganizationsError,
        isRecentOrganizationsMutating,
    } = useOrganizationsCacheContextInternal();

    return {
        recentOrganizations,
        recentOrganizationsMutationError,
        isRecentOrganizationsLoading,
        getRecentOrganizationsError,
        isRecentOrganizationsMutating,
    };
};
