/**
 * SWR caches requests based on keys. So
 * for any requests that only load once and are
 * not dynamic, we can create a distinct key for it.
 *
 * These must only be used ONCE.
 */

export enum SwrStaticKeys {
    ALL_ORGS = 'ALL_ORGS_V2',
    OKTA_GET_USER = 'OKTA_GET_USER',
}
