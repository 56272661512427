import { RccEventEmitter } from 'src/classes/event-emitter';
import { AppEnv, RccEnvironment, ServiceTypeEndpoints } from '../types';

export const Global: AppEnv = {
    Services: null,
    AccessToken: '',
    NepOrganization: '',
    IdmUrl: null,
    Environment: null,
};

type RccIdmKeys = Extract<RccEnvironment, 'dev' | 'stg' | 'prd' | 'local'>;

export const IDMUrlsByEnv: Record<RccIdmKeys, string> = {
    local: 'https://id-dev.ncrcloud.com',
    dev: 'https://id-dev.ncrcloud.com',
    stg: 'https://id-stg.ncrcloud.com',
    prd: 'https://identity.ncr.com',
};

//MARKET-7655 home dev api url for current work around. 
//Will change once we have a longer term solution
export const ApigeeGateways: Record<RccEnvironment, ServiceTypeEndpoints> = {
    local: {
        Provisioning: "https://gateway-dev-x.ncrcloud.com/provisioning",
        Applications: "https://gateway-dev-x.ncrcloud.com/applications",
        FrontDoor: "http://localhost:4001/api",
        Users: "https://gateway-dev-x.ncrcloud.com/users"
    },
    dev: {
        Provisioning: "https://gateway-dev-x.ncrcloud.com/provisioning",
        Applications: "https://gateway-dev-x.ncrcloud.com/applications",
        FrontDoor: "https://home-dev.ncrcloud.com/api",
        Users: "https://gateway-dev-x.ncrcloud.com/users"
    },
    stg: {
        Provisioning: "https://gateway-staging-x.ncrcloud.com/provisioning",
        Applications: "https://gateway-staging-x.ncrcloud.com/applications",
        FrontDoor: "https://home-stg.ncrcloud.com/api",
        Users: "https://gateway-staging-x.ncrcloud.com/users"
    },
    prd: {
        Provisioning: "https://api.ncr.com/provisioning",
        Applications: "https://api.ncr.com/applications",
        FrontDoor: "https://home-prd.ncrcloud.com/api",
        Users: "https://api.ncr.com/users",
    },
    napac: {
        Provisioning: "https://api-napac.ncr.com/provisioning",
        Applications: "https://api-napac.ncr.com/applications",
        FrontDoor: "https://home-napac.ncrcloud.com/api",
        Users: "https://api-napac.ncr.com/users",
    },
    apac: {
        Provisioning: "https://api-apac.ncr.com/provisioning",
        Applications: "https://api-apac.ncr.com/applications",
        FrontDoor: "https://home-apac.ncrcloud.com/api",
        Users: "https://api-apac.ncr.com/users"
    },
    emea: {
        Provisioning: "https://api-emea.ncr.com/provisioning",
        Applications: "https://api-emea.ncr.com/applications",
        FrontDoor: "https://home-emea.ncrcloud.com/api",
        Users: "https://api-emea.ncr.com/users",
    },
};

export const GlobalEmitter = new RccEventEmitter();
