import EditIcon from '@mui/icons-material/Edit';
import { Button, IconButton } from '@mui/material';
import useMobileView from 'src/hooks/useMobileView';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { ButtonProps } from '@mui/material/Button';

export default function EditButton({ ...props }: Omit<ButtonProps, 'type'>) {
    const isMobile = useMobileView();
    const { t } = useTranslationNs({ keyPrefix: 'COMMON' });

    if (isMobile) {
        return (
            <IconButton type='button' size='small' data-element-id='edit-button' {...props}>
                <EditIcon fontSize='inherit' />
            </IconButton>
        );
    }

    return (
        <Button
            type='button'
            data-element-id='edit-button'
            {...props}
            sx={{
                textTransform: 'unset',
                textDecoration: 'underline',
            }}
        >
            {t('BUTTONS.EDIT')}
        </Button>
    );
}
