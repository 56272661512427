import UAParser from 'ua-parser-js';

let parser = new UAParser(window.navigator.userAgent);
let parserResults = parser.getResult();

export function getBrowserName(): string {
    return parserResults.browser.name || '';
}

export function getBrowserVersion(): string {
    return parserResults.browser.version || '';
}

export function getOSName(): string {
    return parserResults.os.name || '';
}

export function getOSVersion(): string {
    return parserResults.os.version || '';
}

export function getMobileVendor(): string {
    return parserResults.device.vendor || '';
}

export function getMobileModel(): string {
    return parserResults.device.model || '';
}
