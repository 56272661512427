import LoginCredentials from 'src/components/user-settings/security/credentials/LoginCredentials/LoginCredentials';
import { SettingsTabContainer } from 'src/components/user-settings/common/SettingContainer';

export const UserSecurity = () => {
    return (
        <SettingsTabContainer>
            <LoginCredentials />
        </SettingsTabContainer>
    );
};
