import { Button, Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import {
    errorHelperTexts,
    ErrorInterface,
    formError,
    validateEmailFormat,
    validateRequired,
} from 'src/utils/form.utils';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import ConfirmationDialog from 'src/components/common/Dialog/ConfirmationDialog';

interface EmailErrors extends ErrorInterface {
    email: null | formError.required | formError.invalidFormat_email | formError.notNcrEmailFormat;
}

const EnterEmail = (props: { currEmail?: string; handleUpdateEmail: (e: string) => void }) => {
    let { currEmail, handleUpdateEmail } = props;
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const { t } = useTranslationNs();

    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState<EmailErrors>({
        email: null,
    });
    const [canSubmit, setCanSubmit] = useState(false);

    useEffect(() => {
        let changesDetected = Boolean(
            email.toString().trim() && email.toString().trim() !== currEmail,
        );
        setCanSubmit(changesDetected && errors.email === null);
    }, [email, currEmail, errors]);

    const updateEmail = () => {
        setErrors({
            email: null,
        });

        handleUpdateEmail(email);
    };

    const confirmUpdate = () => {
        setConfirmDialogOpen(true);
    };

    function onKeyDown(e: any) {
        if (e.key === 'Enter' && canSubmit) {
            updateEmail();
        }
    }

    return (
        <Stack gap={2} mt={2}>
            <ConfirmationDialog
                open={confirmDialogOpen}
                setOpen={setConfirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
                onConfirm={() => {
                    updateEmail();
                }}
            />
            <TextField
                required
                fullWidth
                label={t('COMMON.PROPS.EMAIL')}
                variant='outlined'
                defaultValue={props.currEmail}
                onChange={(e) => {
                    setEmail(e.target.value);
                    validateRequired(e.target.value, 'email', errors, setErrors);
                    validateEmailFormat(e.target.value, 'email', errors, setErrors, false, true);
                }}
                error={
                    errors.email === formError.invalidFormat_email ||
                    errors.email === formError.notNcrEmailFormat
                }
                helperText={
                    (errors.email === formError.invalidFormat_email &&
                        t(errorHelperTexts.invalidFormat_email)) ||
                    (errors.email === formError.notNcrEmailFormat &&
                        t(errorHelperTexts.notNcrEmailFormat))
                }
                onBlur={(e) => {
                    validateEmailFormat(
                        e.target.value.trim(),
                        'email',
                        errors,
                        setErrors,
                        false,
                        true,
                    );
                    setEmail(e.target.value.trim());
                }}
                onKeyDown={onKeyDown}
            />
            <Button
                variant='contained'
                onClick={confirmUpdate}
                disabled={!canSubmit}
                fullWidth
                sx={{
                    textTransform: 'unset',
                }}
            >
                {t('COMMON.BUTTONS.SUBMIT')}
            </Button>
        </Stack>
    );
};

export default EnterEmail;
