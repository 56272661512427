import { useEffect, useState } from 'react';

export const setSearchParam = (
    key: string,
    value: string,
) => {
    if ('URLSearchParams' in window) {
        const url = new URL(window.location.toString())
        url.searchParams.set(key, value)
        history.pushState(null, '', url);
    }
};

export const deleteSearchParam = (
    key: string
) => {
    if ('URLSearchParams' in window) {
        const url = new URL(window.location.href);
        url.searchParams.delete(key);
        window.history.replaceState(null, null, url);
    }
};

export const getSearchParam = (
    key: string
): string => {
    if ('URLSearchParams' in window) {
        let searchParams = new URLSearchParams(window.location.search);
        return searchParams.get(key);
    }
};

export const useLocation = () => {
    const [location, setLocation] = useState<Location>(window.location ?? undefined)

    useEffect(() => {
        const setWindowLocation = () => {
            setLocation(window.location)
        }

        if (!location) {
            setWindowLocation()
        }

        window.addEventListener('popstate', setWindowLocation)

        return () => {
            window.removeEventListener('popstate', setWindowLocation)
        }
    }, [location])

    return location
}