import { useOktaAuth } from '@okta/okta-react';
import { useCallback } from 'react';
import { SwrStaticKeys } from 'src/types/swr-keys.type';
import useSWR from 'swr';
import {
    addEmail,
    OktaAuth,
    sendEmailChallenge,
    verifyEmailChallenge,
    updateProfile,
    UserClaims,
} from '@okta/okta-auth-js';
import { VerificationPayload } from '@okta/okta-auth-js/types/lib/myaccount/types';
import BaseTransaction from '@okta/okta-auth-js/types/lib/myaccount/transactions/Base';

export const useGetOktaUser = (): {
    userInfo: UserClaims;
    refreshUserInfo: () => Promise<void>;
} => {
    const { authState, oktaAuth } = useOktaAuth();

    const { data: userInfo, mutate } = useSWR(
        authState?.isAuthenticated ? SwrStaticKeys.OKTA_GET_USER : null,
        async () => {
            return await oktaAuth.getUser();
        },
        {
            revalidateOnFocus: false,
        },
    );

    const refreshUserInfo = useCallback(async () => {
        await mutate();
    }, [mutate]);

    return { userInfo, refreshUserInfo };
};

const updateOktaProfile = (
    oktaAuth: OktaAuth,
    firstName: string,
    lastName: string,
    email: string,
): Promise<void> => {
    const nullValueFields = {
        department: null,
        mobilePhone: null,
        userType: null,
        managerId: null,
        manager: null,
        preferredLanguage: null,
        honorificPrefix: null,
        honorificSuffix: null,
        costCenter: null,
        middleName: null,
        profileUrl: null,
        locale: null,
        title: null,
        secondEmail: null,
        division: null,
        displayName: null,
        organization: null,
        timezone: null,
        nickName: null,
        employeeNumber: null,
    };

    return new Promise((resolve, reject) => {
        updateProfile(oktaAuth, {
            payload: {
                profile: {
                    firstName: firstName,
                    lastName: lastName,
                    login: email,
                    email: email,
                    ...nullValueFields,
                },
            },
        })
            .then(() => resolve())
            .catch((error) => reject(error));
    });
};

const updateUserEmail = (oktaAuth: OktaAuth, email: string) => {
    const addEmailPayload = {
        profile: {
            email: email,
        },
        sendEmail: false,
        role: 'PRIMARY',
    };

    return addEmail(oktaAuth, {
        payload: addEmailPayload,
    });
};

const verifyUserEmail = (
    verificationCode: string,
    verify: (payload: VerificationPayload) => Promise<BaseTransaction>,
) => {
    const newVerificationPayload = {
        verificationCode: verificationCode,
    };
    return verify(newVerificationPayload);
};

const verifyUserEmailChallenge = (
    oktaAuth: OktaAuth,
    id: string,
    challengeId: string,
    verificationCode: string,
) => {
    const newVerificationPayload = {
        verificationCode: verificationCode,
    };

    return verifyEmailChallenge(oktaAuth, {
        emailId: id,
        challengeId: challengeId,
        payload: newVerificationPayload,
    });
};

const sendUserEmailChallenge = (oktaAuth: OktaAuth, emailId: string | undefined) => {
    return sendEmailChallenge(oktaAuth, { id: emailId });
};

export const oktaService = {
    updateUserEmail,
    verifyUserEmail,
    sendUserEmailChallenge,
    verifyUserEmailChallenge,
    updateOktaProfile,
};
