import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const PasswordTextField = ({
    label,
    value,
    error,
    setValue,
    setTouched,
}: {
    label: string;
    value?: string;
    error?: boolean;
    setValue: (val: string) => void;
    setTouched: (val: boolean) => void;
}) => {
    const [hide, setHide] = useState(true);
    const toggleVisibility = () => {
        setHide((val) => !val);
    };

    const handleChange = (e: any) => {
        setValue(e.target.value);
        setTouched(true);
    };

    return (
        <TextField
            data-cy={label}
            label={label}
            value={value}
            error={error}
            type={hide ? 'password' : 'text'}
            onChange={handleChange}
            fullWidth
            required
            InputProps={{
                endAdornment: (
                    <InputAdornment position='end' sx={{ width: 'auto' }}>
                        <IconButton
                            aria-label='toggle password visibility'
                            onClick={toggleVisibility}
                            onMouseDown={(e) => e.preventDefault()}
                            edge='end'
                            tabIndex={1}
                        >
                            {hide ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default PasswordTextField;
