import Button from '@mui/material/Button';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { Stack } from '@mui/material';
import { MouseEventHandler, ReactNode } from 'react';
import useMobileView from 'src/hooks/useMobileView';
import Drawer from '@mui/material/Drawer';

export interface SaveCancelProps {
    onSave?: MouseEventHandler;
    onCancel?: MouseEventHandler;
    saveText?: ReactNode | string;
    cancelText?: ReactNode | string;
    loadingButton?: boolean;
    disabled?: boolean;
    cancelDisabled?: boolean;
    fullWidth?: boolean;
}

export const SaveCancelButton = ({
    saveText,
    cancelText,
    onSave,
    onCancel,
    loadingButton,
    disabled,
    cancelDisabled,
    fullWidth,
}: SaveCancelProps) => {
    const { t } = useTranslationNs({ keyPrefix: 'COMMON' });

    return (
        <Stack direction='row' gap={2}>
            <Button
                variant='outlined'
                onClick={onCancel}
                disabled={cancelDisabled}
                sx={{
                    textTransform: 'unset',
                }}
                fullWidth={fullWidth}
            >
                {cancelText ? cancelText : t('BUTTONS.CANCEL')}
            </Button>
            <LoadingButton
                variant='contained'
                loading={loadingButton}
                onClick={onSave}
                disabled={disabled}
                sx={{
                    textTransform: 'unset',
                }}
                fullWidth={fullWidth}
            >
                {saveText ? saveText : t('BUTTONS.SAVE')}
            </LoadingButton>
        </Stack>
    );
};

const MobileSaveCancelDrawer = (props: SaveCancelProps) => {
    const { cancelText, onCancel, saveText, onSave, loadingButton, disabled, cancelDisabled } =
        props;
    const { t } = useTranslationNs({ keyPrefix: 'COMMON' });

    return (
        <Drawer
            anchor='bottom'
            variant='permanent'
            PaperProps={{
                sx: {
                    height: '150px',
                    justifyContent: 'center',
                },
            }}
        >
            <Stack spacing={2} sx={{ alignItems: 'center ' }}>
                <Button
                    variant='outlined'
                    sx={{
                        width: '90%',
                    }}
                    onClick={onCancel}
                    disabled={cancelDisabled}
                >
                    {cancelText ? cancelText : t('BUTTONS.CANCEL')}
                </Button>
                <LoadingButton
                    variant='contained'
                    sx={{
                        width: '90%',
                    }}
                    loading={loadingButton}
                    onClick={onSave}
                    disabled={disabled}
                >
                    {saveText ? saveText : t('BUTTONS.SAVE')}
                </LoadingButton>
            </Stack>
        </Drawer>
    );
};

const ResponsiveSaveCancelButton = (props: SaveCancelProps) => {
    const isMobileView = useMobileView();

    if (isMobileView) {
        return <MobileSaveCancelDrawer {...props} />;
    }

    return <SaveCancelButton {...props} />;
};

export default ResponsiveSaveCancelButton;
