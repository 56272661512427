// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --rcc-styles-user-settings-padding: 32px;
    --rcc-styles-config-switcher-width: 280px;
    --rcc-styles-border-radius: 8px;
    --rcc-styles-drawer-list-item-padding: 12px 16px;
    --rcc-styles-menu-item-padding: 12px 16px;
    --rcc-styles-dialog-border-radius: 16px;
    --rcc-styles-mobile-dialog-width: 85vw;
}

@media screen and (max-width:600px) {
    :root {
        --rcc-styles-user-settings-padding: 16px;
        --rcc-styles-config-switcher-width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/rcc/RccStyles.css"],"names":[],"mappings":"AAAA;IACI,wCAAwC;IACxC,yCAAyC;IACzC,+BAA+B;IAC/B,gDAAgD;IAChD,yCAAyC;IACzC,uCAAuC;IACvC,sCAAsC;AAC1C;;AAEA;IACI;QACI,wCAAwC;QACxC,wCAAwC;IAC5C;AACJ","sourcesContent":[":root {\n    --rcc-styles-user-settings-padding: 32px;\n    --rcc-styles-config-switcher-width: 280px;\n    --rcc-styles-border-radius: 8px;\n    --rcc-styles-drawer-list-item-padding: 12px 16px;\n    --rcc-styles-menu-item-padding: 12px 16px;\n    --rcc-styles-dialog-border-radius: 16px;\n    --rcc-styles-mobile-dialog-width: 85vw;\n}\n\n@media screen and (max-width:600px) {\n    :root {\n        --rcc-styles-user-settings-padding: 16px;\n        --rcc-styles-config-switcher-width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
