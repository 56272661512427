import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { UserSettingKeys } from 'src/constants/user-settings-keys';
import { useUserSettingsContext } from 'src/contexts/UserSettingsContext';
import { Organization } from 'src/types';
import { useGetOrganizationByName } from './organization.fetchers';

type PreloadFetcher = (id: number, preloadOptionId: number, setPreloadOptionId: Dispatch<SetStateAction<number>>, ...args: any) => { data: Organization, reset: () => void };

export const useGetPreloadSearchQuery: PreloadFetcher = (id: number, preloadOptionId: number, setPreloadOptionId: Dispatch<SetStateAction<number>>, isAuthenticated: boolean, searchParam: string) => {
    const isReady = isAuthenticated && preloadOptionId == id;
    const { organizationData, errorOrg } = useGetOrganizationByName(isReady ? searchParam : null);
    const [data, setData] = useState<Organization>();

    useEffect(() => {
        if (!isReady) return

        if (!searchParam || !!errorOrg) {
            setPreloadOptionId((id) => ++id);
            return;
        }

        if (!!organizationData) {
            setData(organizationData);
        }
    }, [errorOrg, isReady, searchParam, organizationData])

    const reset = useCallback(() => {
        setData(undefined);
    }, []);

    return { data, reset };
}

export const useGetPreloadDefault: PreloadFetcher = (id: number, preloadOptionId: number, setPreloadOptionId: Dispatch<SetStateAction<number>>, isAuthenticated: boolean) => {
    const { userSettings, getUserSettingByKey } = useUserSettingsContext();
    const defaultOrgSetting = getUserSettingByKey(UserSettingKeys.DefaultOrg);
    const isReady = isAuthenticated && preloadOptionId == id;
    const { organizationData, errorOrg } = useGetOrganizationByName(isReady ? defaultOrgSetting : null);
    const [data, setData] = useState<Organization>();

    useEffect(() => {
        if (!isReady) return

        if ((userSettings && !defaultOrgSetting) || !!errorOrg) {
            setPreloadOptionId((id) => ++id);
            return;
        }

        if (!!organizationData) {
            setData(organizationData);
        }
    }, [isReady, errorOrg, userSettings, defaultOrgSetting, organizationData])

    const reset = useCallback(() => {
        setData(undefined);
    }, []);

    return { data, reset };
}

export const useGetPreloadFirstOrganization: PreloadFetcher = (id: number, preloadOptionId: number, setPreloadOptionId: Dispatch<SetStateAction<number>>, isAuthenticated: boolean, isLoadingEffectiveOrgs: boolean, userOrganizations: Organization[]) => {
    const isReady = isAuthenticated && preloadOptionId == id && !isLoadingEffectiveOrgs;
    const firstOrg = userOrganizations?.length > 0 ? userOrganizations[0] : null;

    const { organizationData, errorOrg } = useGetOrganizationByName(isReady ? firstOrg?.organizationName : null);
    const [data, setData] = useState<Organization>();

    useEffect(() => {
        if (!isReady) return

        if (!!errorOrg || userOrganizations?.length == 0) {
            setPreloadOptionId((id) => ++id);
            return;
        }

        if (!!organizationData) {
            setData(organizationData);
        }
    }, [isReady, errorOrg, organizationData, userOrganizations]);


    const reset = useCallback(() => {
        setData(undefined);
    }, []);


    return { data, reset };
}
