export const AppConstants = {
    OrgMenuMax: 5,
    AppMenuMax: 5,
    EffectiveOrgsLimit: 30,
    NcrEmailFormat: '@ncr.com',
    NcrVoyixEmailFormat: '@ncrvoyix.com',
    i18NS: 'rcc',
    OrgHeader: 'nep-organization',
    GlobalConfigSetName: 'v1_home_global_settings',
};
