import { Box, TextField } from '@mui/material';
import _ from 'lodash';
import { useRef } from 'react';

const OneTimePasscode = (props: {
    length: number;
    error: boolean | undefined;
    disabled: boolean;
    value: any;
    setValue: (v: any) => void;
}) => {
    let { length, error, disabled, value, setValue } = props;
    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <>
            <Box
                sx={{
                    py: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    lineHeight: '1em',
                    maxHeight: '2em',
                }}
            >
                {_.range(length).map((key) => {
                    return (
                        <TextField
                            key={key}
                            variant='outlined'
                            focused={value.length === key}
                            value={value[key] ?? ''}
                            sx={{
                                fontSize: '15px',
                                mx: '8px',
                            }}
                            inputProps={{
                                style: {
                                    textAlign: 'center',
                                    paddingLeft: '5px',
                                    paddingRight: '5px',
                                    textTransform: 'uppercase',
                                    cursor: 'pointer',
                                },
                            }}
                            error={error}
                            disabled={disabled}
                            onClick={() => {
                                if (inputRef.current) {
                                    setValue((current: any) => current.substring(0, key));
                                    inputRef.current.focus();
                                }
                            }}
                            onKeyDown={() => {
                                if (inputRef.current) {
                                    setValue((current: any) => current.substring(0, key));
                                    inputRef.current.focus();
                                }
                            }}
                        />
                    );
                })}
            </Box>
            <input
                ref={inputRef}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                maxLength={length}
                style={{ opacity: '0', cursor: 'default' }}
            />
        </>
    );
};
export default OneTimePasscode;
