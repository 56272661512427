import { InputLabel, Stack, TextField, Typography } from '@mui/material';
import { ChangeEvent, forwardRef } from 'react';
import { ThemeValues } from 'src/constants/theme-values';
import { useSyncRef } from 'src/hooks/useSyncRef';

export type TextInputProps = {
    label: string;
    description?: string;
    disabled?: boolean;
    error?: boolean;
    editMode?: boolean;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    defaultValue?: string;
    fullWidth?: boolean;
    required?: boolean;
};

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
    (
        {
            label,
            description,
            disabled,
            error,
            editMode,
            defaultValue,
            fullWidth,
            onChange,
            required,
        }: TextInputProps,
        ref,
    ) => {
        const inputRef = useSyncRef<HTMLInputElement>(ref);

        if (!editMode) {
            return (
                <Stack rowGap={'8px'}>
                    <Typography variant='subtitle2' fontWeight='500' color='text.primary'>
                        {label}
                    </Typography>
                    <Typography variant='body1' fontWeight='400' color='text.secondary'>
                        {defaultValue || ''}
                    </Typography>
                </Stack>
            );
        }

        return (
            <Stack rowGap={'8px'}>
                <InputLabel required={required} error={!!error}>
                    {label}
                </InputLabel>
                <TextField
                    required={required}
                    defaultValue={defaultValue}
                    inputRef={inputRef}
                    disabled={disabled}
                    fullWidth={fullWidth}
                    onChange={onChange}
                    autoComplete='off'
                    error={!!error}
                    onBlur={(event) => {
                        event.target.value = event.target.value.trim();
                    }}
                    InputProps={{
                        sx: { borderRadius: ThemeValues.BorderRadius },
                    }}
                />
                {description && (
                    <Typography color={error ? 'error' : undefined} fontSize={'14px'}>
                        {description}
                    </Typography>
                )}
            </Stack>
        );
    },
);
