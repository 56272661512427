import { AppConstants } from 'src/constants/app';
import { DefaultErrorHandler } from 'src/utils/services.utils';
import { ResetPasswordDto, UpdateUserProfileDto } from '..';
import { Global } from '../constants/global';
import { post, put } from './common.fetchers';

const baseUrl = () => Global.Services.Users;

// TODO: This endpoint is waiting for CORS support
const _updateUserPassword = (resetPasswordDto: ResetPasswordDto): Promise<null> => {
    return post<null, ResetPasswordDto>(`${baseUrl()}/change-my-password`, resetPasswordDto, {
        headers: {
            [AppConstants.OrgHeader]: null,
        },
    }).catch(DefaultErrorHandler);
};

// TODO: This endpoint is waiting for CORS support
const _updateUserProfileSelf = (profileDto: UpdateUserProfileDto): Promise<null> => {
    return put<null, UpdateUserProfileDto>(`${baseUrl()}/self`, profileDto, {
        headers: {
            [AppConstants.OrgHeader]: null, // TODO: can support legacy organization header is included, follow up if we want this
        },
    }).catch(DefaultErrorHandler);
};

export { _updateUserPassword, _updateUserProfileSelf };
