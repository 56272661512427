import { Button } from '@mui/material';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { useRccContext } from 'src/contexts';
import { SettingField } from 'src/components/user-settings/common/SettingContainer';
import { useState } from 'react';
import ResetPasswordModal from 'src/components/user-settings/security/credentials/LoginCredentials/ResetPassword/ResetPasswordModal';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { SettingsPanelBox } from 'src/components/user-settings/common/SettingsPanel';

const LoginCredentials = () => {
    const { t } = useTranslationNs({
        keyPrefix: 'USER_SETTINGS.SECURITY',
    });

    const [editMode, setEditMode] = useState<boolean>(false);

    const { userInfo } = useRccContext();

    function handleLeave() {
        setEditMode(false);
    }

    return (
        <SettingsPanelBox title={t('CREDENTIALS')} icon={<VpnKeyIcon />}>
            <SettingField name={t('PASSWORD')} description={t('CHANGE_PASSWORD_SUBTITLE')}>
                {editMode && (
                    <ResetPasswordModal
                        firstName={userInfo?.given_name ?? ''}
                        lastName={userInfo?.family_name ?? ''}
                        onClose={handleLeave}
                    />
                )}
                <Button
                    variant='outlined'
                    disabled={editMode}
                    sx={{
                        textTransform: 'unset',
                    }}
                    onClick={() => {
                        setEditMode(true);
                    }}
                >
                    {t('CHANGE_PASSWORD')}
                </Button>
            </SettingField>
        </SettingsPanelBox>
    );
};

export default LoginCredentials;
