import { ReactNode, useMemo } from 'react';
import { useRccContext } from 'src/contexts';
import {
    IOrganizationsCacheContext,
    OrganizationsCacheContext,
} from 'src/contexts/OrganizationsCacheContext';
import { useDeleteUserEffectiveOrganizationsCache } from 'src/fetchers/organization.fetchers';
import { useRecentOrganizations } from 'src/fetchers/user-recent-organizations.fetcher';

export const OrganizationsCacheProvider = ({ children }: { children: ReactNode }) => {
    const { isAuthenticated } = useRccContext();
    const {
        recentOrganizations,
        recentOrganizationsMutationError,
        isRecentOrganizationsLoading,
        getRecentOrganizationsError,
        isRecentOrganizationsMutating,
        updateRecentsWithNewOrganization: updateUserRecentOrganizations,
    } = useRecentOrganizations(isAuthenticated);
    const deleteCache = useDeleteUserEffectiveOrganizationsCache();

    const contextValue: IOrganizationsCacheContext = useMemo(
        () => ({
            recentOrganizations,
            recentOrganizationsMutationError,
            isRecentOrganizationsLoading,
            getRecentOrganizationsError,
            isRecentOrganizationsMutating,
            updateUserRecentOrganizations,
            deleteCache,
        }),
        [
            recentOrganizations,
            recentOrganizationsMutationError,
            isRecentOrganizationsLoading,
            getRecentOrganizationsError,
            isRecentOrganizationsMutating,
            updateUserRecentOrganizations,
            deleteCache,
        ],
    );

    return (
        <OrganizationsCacheContext.Provider value={contextValue}>
            {children}
        </OrganizationsCacheContext.Provider>
    );
};
