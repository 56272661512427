import { Box, Divider, InputLabel, Stack } from '@mui/material';
import { Fragment, useEffect, useRef, useState } from 'react';
import { SwitcherDialog } from 'src/components/common/Switcher/SwitcherDialog';
import { AppConstants } from 'src/constants/app';
import { useOrganizationContext } from 'src/contexts';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { Organization } from 'src/types';
import { OrganizationCheckerInput } from '../OrganizationCheckerInput/OrganizationCheckerInput';
import {
    OrganizationDialogProvider,
    useOrganizationDialogContext,
} from '../OrganizationProviders/OrganizationDialogProvider';
import { OrganizationAllOrgsList } from './OrganizationAllOrgsList';

export const OrganizationCheckerInputWrapper = ({
    onCtaClick,
}: {
    onCtaClick: (organization: Organization) => void;
}) => {
    const [searchTerm, setSearchTerm] = useState<string>();
    const [activeTerm, setActiveTerm] = useState<string>();
    const isInitialLoad = useRef(true);
    const inputRef = useRef<HTMLInputElement>();
    const { organization, isLoading } = useOrganizationContext();

    useEffect(() => {
        if (isInitialLoad.current && organization) {
            const orgName = organization.organizationName;
            setSearchTerm(orgName);
            setActiveTerm(orgName);
            inputRef.current.value = orgName;
            isInitialLoad.current = false;
        }
    }, [organization]);

    return (
        <OrganizationCheckerInput
            ref={inputRef}
            onCtaClick={onCtaClick}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            activeTerm={activeTerm}
            setActiveTerm={setActiveTerm}
            isDisabled={isLoading}
        />
    );
};

const OrganizationSwitcherDialogInner = () => {
    const { t } = useTranslationNs();

    const { handleOrganizationClick } = useOrganizationDialogContext();
    const { totalOrganizations, isOrgDialogOpen, setIsOrgDialogOpen } = useOrganizationContext();
    const enableSearch = totalOrganizations > AppConstants.EffectiveOrgsLimit;

    const handleDialogClose = () => {
        setIsOrgDialogOpen(false);
    };

    const handleOrgCheckerInputCta = (organization: Organization) => {
        handleOrganizationClick(organization.organizationName);
    };

    return (
        <SwitcherDialog
            isOpen={isOrgDialogOpen}
            dialogTitle={t('ORG_CONTEXT.MENU.TITLE')}
            dialogCloseTitle={t('ORG_CONTEXT.ACCESSIBILITY.CLOSE')}
            onClose={handleDialogClose}
            dialogContentSx={{
                paddingLeft: '12px',
                paddingRight: '12px',
            }}
            dialogTitleSlot={
                <Fragment>
                    {enableSearch && (
                        <Stack rowGap={'24px'}>
                            <OrganizationCheckerInputWrapper
                                onCtaClick={handleOrgCheckerInputCta}
                            />
                            <Divider />
                        </Stack>
                    )}
                </Fragment>
            }
        >
            <Stack rowGap='8px'>
                <Box paddingX='12px'>
                    <InputLabel>
                        {t('ORG_CONTEXT.DIALOG.LIST_TITLE', {
                            limit: AppConstants.EffectiveOrgsLimit,
                        })}
                    </InputLabel>
                </Box>
                <OrganizationAllOrgsList />
            </Stack>
        </SwitcherDialog>
    );
};

export const OrganizationSwitcherDialog = () => {
    const { setIsOrgDialogOpen, updateOrganization } = useOrganizationContext();

    return (
        <OrganizationDialogProvider
            setIsOrgDialogOpen={setIsOrgDialogOpen}
            updateOrganization={updateOrganization}
        >
            <OrganizationSwitcherDialogInner />
        </OrganizationDialogProvider>
    );
};
