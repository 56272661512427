import pino, { LogEvent } from 'pino';
import { Global } from 'src/constants/global';
import { LogType } from 'src/types/log.type';
import axios from 'axios';
import {
    getBrowserName,
    getBrowserVersion,
    getMobileModel,
    getMobileVendor,
    getOSName,
    getOSVersion,
} from 'src/utils/user-agent.utils';

declare const PACKAGE_VERSION: string;

const baseUrl = () => Global.Services.FrontDoor;

const loggingClient = axios.create();

const commonLog: Record<string, any> = {
    key: 'base',
    browser: `${getBrowserName()} ${getBrowserVersion()}`,
    os: `${getOSName()} ${getOSVersion()}`,
    mobile: getMobileVendor() !== '' ? `${getMobileVendor()} ${getMobileModel()}` : null,
    location: window.location.host,
    package: PACKAGE_VERSION || undefined,
};

const _sendLog = (payload: object) => {
    loggingClient
        .post<null, object>(`${baseUrl()}/ccl`, payload, {
            headers: {
                Authorization: `AccessToken ${Global.AccessToken}`,
            },
        })
        .catch(() => {
            // Ignore logging errors
        });
};

const getLogLevel = () => {
    switch (Global.Environment) {
        case 'local':
        case 'dev':
        case 'stg':
        case 'prd':
        case 'apac':
        case 'emea':
        case 'napac':
        default:
            return 'warn';
    }
};

const formatLog = (logEvent: LogEvent): LogType => {
    const baseLog = logEvent.bindings.find((binding) => binding.key === 'base');

    return {
        browser: baseLog.browser,
        os: baseLog.os,
        mobile: baseLog.mobile,
        location: baseLog.location,
        package: baseLog.package,
        severity: logEvent.level.label,
        messages: logEvent.messages,
    };
};

export const logger = pino({
    level: getLogLevel(),
    browser: {
        asObject: true,
        transmit: {
            level: getLogLevel(),
            send: function (level, logEvent) {
                if (Global.Environment === 'local') {
                    return;
                }

                _sendLog(formatLog(logEvent));
            },
        },
    },
}).child(commonLog);
