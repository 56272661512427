import { UnfoldMore } from '@mui/icons-material';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton/LoadingButton';
import { Box, Tooltip, useTheme, SxProps, Theme } from '@mui/material';
import { ForwardedRef, ReactNode, forwardRef } from 'react';
import { ThemeValues } from 'src/constants/theme-values';

export const getActiveTextStyle: SxProps<Theme> = (theme: Theme) => {
    return {
        ...theme.typography.subtitle1,
        color: theme.palette.text.primary,
        fontWeight: 600,
    };
};

export type SwitcherProps = {
    children: ReactNode;
    tooltipTitle: string;
    enableSwitcher: boolean;
    isLoading: boolean;
    onClick: () => void;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    disabled?: boolean;
    loadingButtonSx?: SxProps<Theme>;
    labelSx?: SxProps<Theme>;
};

export const Switcher = forwardRef<HTMLButtonElement, SwitcherProps>(
    (
        {
            children,
            disabled,
            enableSwitcher,
            tooltipTitle,
            isLoading,
            onClick,
            loadingButtonSx = {},
            labelSx = {},
            startIcon,
            endIcon = (
                <UnfoldMore
                    fontSize='small'
                    sx={{
                        color: (theme) => theme.palette.action.active,
                        height: '24px',
                        width: '24px',
                    }}
                />
            ),
            ...rest
        }: SwitcherProps,
        ref,
    ) => {
        const theme = useTheme();
        const disableSwitcher = disabled == undefined ? isLoading : disabled;
        const startIconProps: Pick<LoadingButtonProps, 'startIcon' | 'loadingPosition'> = {
            startIcon: startIcon,
            loadingPosition: 'start',
        };

        return (
            <Tooltip title={tooltipTitle}>
                <span>
                    <LoadingButton
                        ref={ref as ForwardedRef<HTMLButtonElement>}
                        loading={isLoading && !!startIcon}
                        onClick={onClick}
                        variant='text'
                        endIcon={isLoading || enableSwitcher ? endIcon : null}
                        sx={{
                            columnGap: 1,
                            borderRadius: ThemeValues.BorderRadius,
                            textTransform: 'none',
                            color: theme.palette.text.primary,
                            fontWeight: 400,
                            padding: '6px',
                            '.MuiButton-endIcon, .MuiButton-startIcon': {
                                marginLeft: 0,
                                marginRight: 0,
                                padding: '2px',
                            },
                            '.MuiButton-endIcon svg, .MuiButton-startIcon svg': {
                                marginRight: '-0.5px',
                            },
                            '.MuiButton-startIcon': {
                                color: theme.palette.action.active,
                            },
                            '&.Mui-disabled': {
                                '& > #switcher-label': {
                                    color: theme.palette.text.secondary,
                                    fontWeight: 500,
                                },
                            },
                            maxWidth: '224px',
                            ...loadingButtonSx,
                        }}
                        disabled={disableSwitcher || isLoading}
                        {...(!!startIcon ? startIconProps : {})}
                        {...rest}
                    >
                        <Box
                            id='switcher-label'
                            sx={{
                                textOverflow: 'ellipsis',
                                textAlign: 'left',
                                whiteSpace: 'nowrap',
                                width: '100%',
                                overflow: 'hidden',
                                ...getActiveTextStyle(theme),
                                ...labelSx,
                            }}
                            aria-live='assertive'
                            aria-atomic='true'
                        >
                            {children}
                        </Box>
                    </LoadingButton>
                </span>
            </Tooltip>
        );
    },
);
