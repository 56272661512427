import { CircularProgress, Stack } from '@mui/material';
import { MutableRefObject, ReactNode, useRef, useState } from 'react';
import { Switcher } from 'src/components/common/Switcher/Switcher';
import {
    SwitcherProvider,
    getDefaultConfig,
} from 'src/components/common/Switcher/SwitcherProvider';
import { useApplicationsContext } from 'src/contexts/ApplicationsContext';
import { SwitcherOptions } from 'src/contexts/SwitcherContext';
import useMobileView from 'src/hooks/useMobileView';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { ApplicationSwitcherDialog } from '../ApplicationSwitcherDialog/ApplicationSwitcherDialog';
import { ApplicationSwitcherDrawer } from './ApplicationSwitcherDrawer';
import { ApplicationSwitcherMenu } from './ApplicationSwitcherMenu';

const ApplicationSwitcherLoadingLabel = ({ title }: { title: string }) => {
    return (
        <Stack alignItems='center' columnGap={1} direction='row'>
            <CircularProgress color='inherit' size={16} />
            {title}
        </Stack>
    );
};

export const ApplicationSwitcher = ({
    config = getDefaultConfig(),
}: {
    config?: SwitcherOptions;
}) => {
    const { t } = useTranslationNs();
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const { isLoading, application, userApplications } = useApplicationsContext();
    const buttonRef: MutableRefObject<HTMLButtonElement | null> = useRef(null);
    const isMobile = useMobileView();

    const handleOpenAppMenu = () => {
        setMenuOpen(true);
    };

    const handleCloseAppMenu = () => {
        setMenuOpen(false);
    };

    let switcherLabel: ReactNode;
    if (isLoading) switcherLabel = <ApplicationSwitcherLoadingLabel title={t('COMMON.LOADING')} />;
    else if (!application) switcherLabel = t('APPLICATION_CONTEXT.SWITCHER.EMPTY');
    else switcherLabel = application?.displayName || application?.name;

    return (
        <SwitcherProvider config={config || {}}>
            <Switcher
                tooltipTitle={t('APPLICATION_CONTEXT.ACCESSIBILITY.SWITCHER')}
                disabled={userApplications.length <= 1}
                enableSwitcher={userApplications.length !== 1}
                isLoading={isLoading}
                onClick={handleOpenAppMenu}
                ref={buttonRef}
                data-element-id='app-switcher-nav-button'
            >
                {switcherLabel}
            </Switcher>
            {isMobile ? (
                <ApplicationSwitcherDrawer
                    menuOpen={menuOpen}
                    onMenuOpen={handleOpenAppMenu}
                    onMenuClose={handleCloseAppMenu}
                />
            ) : (
                <ApplicationSwitcherMenu
                    anchorRef={buttonRef}
                    menuOpen={menuOpen}
                    onMenuClose={handleCloseAppMenu}
                />
            )}
            <ApplicationSwitcherDialog />
        </SwitcherProvider>
    );
};
