import { PropsWithChildren, ReactNode } from 'react';
import React from 'react';
import { logger } from 'src/clients/logger';

/*
 * Wrap major common components with error boundary and for error handling and logging for rendering issues.
 */
const RccBoundary = ({ children }: PropsWithChildren) => {
    return <ErrorBoundary>{children}</ErrorBoundary>;
};

interface ErrorBoundaryProps {
    children: ReactNode;
    fallback?: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        logger.error(error, info.componentStack);
    }

    render() {
        if (this.state.hasError && this.props.fallback) {
            return this.props.fallback;
        }

        return this.props.children;
    }
}

export default RccBoundary;
