import { DefaultOrganizationPreferences } from './defaults/DefaultOrganizationPreference/DefaultOrganizationPreference';
import { SettingsTabContainer } from 'src/components/user-settings/common/SettingContainer';

export const UserPreferences = () => {
    return (
        <SettingsTabContainer>
            <DefaultOrganizationPreferences />
        </SettingsTabContainer>
    );
};
