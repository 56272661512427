import OktaAuth, { UserClaims } from '@okta/okta-auth-js';
import { AppConstants } from 'src/constants/app';

export const isInternalUser = (user: UserClaims): boolean | undefined => {
    if (!user) return undefined;

    return user?.email?.endsWith(AppConstants.NcrEmailFormat);
};

// Account for case where email reflects previous while username reflects new unverified.
export const getEmail = (user: UserClaims): string => {
    return user.email_verified ? user.email : user.preferred_username;
};
