import { useTheme } from '@mui/material';
import _ from 'lodash';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Typography from '@mui/material/Typography';

const StrengthPip = ({ status }: { status: 'incomplete' | 'complete' }) => {
    const theme = useTheme();

    return (
        <div
            style={{
                flexBasis: 0,
                flexGrow: 1,
                position: 'relative',
                maxWidth: '100%',
                width: '100%',
                height: '8px',
                backgroundColor: status === 'complete' ? theme.palette.success.main : '#D9D9D9',
            }}
        />
    );
};

export const StrengthBar = ({
    value,
    max,
    message,
}: {
    value: number;
    max: number;
    message?: string;
}) => {
    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    gap: '8px',
                    alignItems: 'center',
                }}
            >
                {_.range(max + 1).map((el: number) => {
                    return <StrengthPip status={el <= value ? 'complete' : 'incomplete'} />;
                })}
                {value === max ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}
            </div>
            <Typography>{message}</Typography>
        </div>
    );
};
