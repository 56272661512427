import { SwitcherDialog } from 'src/components/common/Switcher/SwitcherDialog';
import { useApplicationsContext } from 'src/contexts';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { ApplicationAllAppsList } from './ApplicationAllAppsList';

export const ApplicationSwitcherDialog = () => {
    const { t } = useTranslationNs();

    const { isAppDialogOpen, setIsAppDialogOpen } = useApplicationsContext();

    const handleAppClick = () => {
        setIsAppDialogOpen(false);
    };

    const handleDialogClose = () => {
        setIsAppDialogOpen(false);
    };

    return (
        <SwitcherDialog
            isOpen={isAppDialogOpen}
            dialogTitle={t('APPLICATION_CONTEXT.MENU.TITLE')}
            dialogCloseTitle={t('APPLICATION_CONTEXT.ACCESSIBILITY.CLOSE')}
            onClose={handleDialogClose}
        >
            <ApplicationAllAppsList onAppClick={handleAppClick} />
        </SwitcherDialog>
    );
};
