import { useCallback, useEffect, useRef, useState } from "react";
import { AppConstants } from "src/constants/app";
import { Global } from "src/constants/global";
import { DefaultErrorHandler } from "src/utils/services.utils";
import { ApiError, Organization } from "..";
import { get, put } from "./common.fetchers";
import { RecentOrganization, UpdateRecentOrganizationsDto } from "src/types/user-preferences.type";
import useSWR from "swr";

const baseUrl = () => Global.Services.FrontDoor;

const getRecentOrganizations = (key: string): Promise<RecentOrganization[]> => {
    return get<RecentOrganization[]>(`${baseUrl()}${key}`, {
        headers: {
            [AppConstants.OrgHeader]: null
        }
    })
        .catch(DefaultErrorHandler);
}

const updateRecentOrganizationsFetcher = (key: string, updates: RecentOrganization[]) => {
    return put<null, UpdateRecentOrganizationsDto>(`${baseUrl()}${key}`, {
        recentOrganizations: updates
    }, {
        headers: {
            [AppConstants.OrgHeader]: null
        }
    })
        .catch(DefaultErrorHandler);
}

export const useRecentOrganizations = (isAuthenticated: boolean) => {
    const key = "/organizations/recents";
    const stateSnapshot = useRef<RecentOrganization[]>();
    const [recentOrganizationsMutationError, setRecentOrganizationsMutationError] = useState<ApiError>();
    const [isRecentOrganizationsMutating, setIsRecentOrganizationsMutating] = useState<boolean>(false);
    const {
        data: recentOrganizations,
        isLoading: isRecentOrganizationsLoading,
        error: getRecentOrganizationsError,
        mutate
    } = useSWR(isAuthenticated ? key : null, getRecentOrganizations);

    useEffect(() => {
        if (!recentOrganizations) return;
        stateSnapshot.current = [...recentOrganizations];
    }, [recentOrganizations])

    const updateRecentOrganizations = useCallback(async (updates: RecentOrganization[]) => {
        try {
            setIsRecentOrganizationsMutating(true);
            await updateRecentOrganizationsFetcher(key, updates).catch(DefaultErrorHandler);
            await mutate(updates, { revalidate: false });
            setIsRecentOrganizationsMutating(false);
        } catch (e: unknown) {
            setRecentOrganizationsMutationError(e as ApiError);
        }
    }, [
        setIsRecentOrganizationsMutating,
        setRecentOrganizationsMutationError,
        mutate
    ])

    const updateRecentsWithNewOrganization = useCallback(
        async (organization: Organization) => {
            const ref = stateSnapshot.current;
            if (ref === undefined) return;

            const { organizationName, displayName } = organization;
            const update: RecentOrganization = {
                organizationName,
                displayName,
            };
            const currentRecents = [...ref];
            const existenceIndex = currentRecents.findIndex(
                (r) => r.organizationName === organizationName,
            );

            if (existenceIndex > -1) {
                currentRecents.splice(existenceIndex, 1);
            }

            currentRecents.unshift(update);
            const updateRecents = currentRecents.slice(0, 5);

            await updateRecentOrganizations(updateRecents);
        },
        [updateRecentOrganizations],
    );

    return {
        recentOrganizations,
        recentOrganizationsMutationError,
        isRecentOrganizationsLoading,
        getRecentOrganizationsError,
        isRecentOrganizationsMutating,
        updateRecentsWithNewOrganization
    }
}