export const ThemeValues = {
    BorderRadius: 'var(--rcc-styles-border-radius)',
    DrawerListItemPadding: 'var(--rcc-styles-drawer-list-item-padding)',
    MenuItemPadding: 'var(--rcc-styles-menu-item-padding)',
    UserSettingsPadding: 'var(--rcc-styles-user-settings-padding)',
    UserConfigSwitcherWidth: 'var(--rcc-styles-config-switcher-width)',
    DialogBorderRadius: 'var(--rcc-styles-dialog-border-radius)',
    MobileDialogWidth: 'var(--rcc-styles-mobile-dialog-width)',
    Elevations: {
        Modal: '1000',
        Drawer: '2000',
    },
    Breakpoints: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
    },
};
